.gallery-image{
    width: 100%;
    height: 300px;
    overflow: hidden;
}
.gallery-image img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
@media only screen and (max-width:800px) {
    .gallery-image {
        margin-bottom: 20px;
    }
}