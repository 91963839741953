.contact-image{
    width: 300px;
    height: 300px;
    overflow: hidden;
    border-radius: 50%;
}
.contact-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.contact-us-icon{
    color: var(--main-color);
    font-size: 20px;
}
.contact-us-background{
    background-color: var(--main-color);
    color: var(--white-color);
    border-radius: 5px;
}
.contact-us-background h5 {
    font-size: 50px;
}
.contact-user-information label{
    margin: 10px 0px;
}
.contact-user-information input{
width: 80%;
border: none;
padding: 10px 10px;
}
.contact-user-information input:focus{
    outline: none;
}
.contact-user-information textarea {
    width: 80%;
    padding: 10px 10px;
    border: none;
}
.contact-user-information textarea:focus{
    outline: none;
}
.contact-user-information button {
    width: 80%;
    border: none;
    padding: 5px 0px ;
}
.contact-user-information button:hover{
    background-color: #277086; 
    color: var(--white-color);

}