.first-banner-row a {
  text-decoration: none !important;
}
.first-banner-row a:hover{
  color: #fff;
}
.first-banner-row {
  padding: 50px 0px 60px 0px;
}
@media only screen and (max-width: 800px) {
  .first-banner-row {
    padding: 25px 0px 25px 0px;
  }
}
.first-banner-image {
  height: 412px;
  width: 75%;
  overflow: hidden;
  padding: 10px 0px;
}
.first-banner-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
@media only screen and (max-width:820px){
  .first-banner-image {
    width: 100%;
  }
}
.first-banner-social-media {
  display: flex;
  align-items: center;
}
.first-banner-icon {
  text-align: center;
  margin: -4px 0px 50px 22px;
  cursor: pointer;
}

.first-facebook a:hover {
  color: var(--facebook-icon-color) !important;
}

.first-twitter a:hover {
  color: var(--twitter-icon-color) !important;
}

.first-instagram a:hover {
  color: var(--instagram-icon-color) !important;
}

.first-banner-heading {
  margin: 123px 0px 98px 0px;
  font-size: 57px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 50px;
}
.fa-square-instagram:hover {
  background: var(--white-color);
}

@media only screen and (max-width: 1000px) {
  .first-banner-heading {
    margin: 50px 0px 30px 0px ;
  }
}
.first-banner-para {
  margin-top: 40px;
  font-size: 20px;
}

.first-banner-btn button {
  padding: 7px 19px;
  margin-top: 3rem;
  background: var(--main-color);
  color: var(--white-color);
  font-size: 15px;
  border-radius: 0 !important;
  border-color: var(--white-color);
  margin-left: 5px;
}

.first-banner-btn i {
  padding-left: 2rem;
  font-size: 12px;
}

.first-banner-btn button:hover {
  background-color: var(--main-color);
  color: white !important;
}

@media only screen and (max-width: 766px) {
  /* .first-banner-heading{
        margin: 0px;
        font-size: 40px;
    } */
  .social-media-section {
    display: flex;
    margin-bottom: -25px;
  }

  .first-banner-para {
    margin-top: 20px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .first-banner-btn {
    margin-top: 20px;
  }
}

.first-banner-btn span {
  font-size: 10px;
  margin-left: 60px;
}
