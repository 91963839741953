.youtube-image{
    height: 300px;
    width: 100%;
    overflow: hidden;
}
.youtube-image img
{
height: 100%;
width: 100%;
object-fit: contain;
}
.section-description 
{
    display: flex;
    align-items: center;
    padding:38px;
    text-align: justify;
}
.section-description h4{
margin-bottom: 15px;
    
}
.section-description p {
color:var(--cart-description-color);
font-family: var(--primary-font);
}
