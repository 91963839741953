/* Footer */

.footer-main a {
  text-decoration: none;
}
.footer-logo {
  height: 100px;
  width: 100px;
  overflow: hidden;
  cursor: pointer;
}
.footer-logo img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.social-media-icon span {
  margin: 0px 10px;
  color: var(--main-color);
  font-size: 22px;
}
.social-media-icon span a {
  color: var(--main-color);
}
.footer-main {
  margin-top: 50px;
}
.footer-main p {
  color: var(--footer-text-color);
  font-family: var(--primary-font);
  /* text-align: justify; */
}

.contact-icon {
  color: var(--main-color);
}

.footer-main i:hover {
  cursor: pointer;
}

.txt-para-sec {
  padding: 0 4.5rem 0 0;
}

.carbon-yuwa {
  font-weight: bold;
  color: #000 !important;
}

.footer-sitemap p {
  line-height: 23.5px;
  cursor: pointer;
}

.footer-sitemap p:hover {
  color: var(--main-color);
}

.social-media-icon {
  padding-top: 1rem;
}

.footer-sitemap h4 {
  margin: 1.5rem 0 1.5rem 0;
  font-size: 18px;
  color: var(--cart-description-color);
  font-weight: bold;
  border-bottom: 2px solid;
  padding-bottom: 5px;
  display: inline-block;
}

.link-sec {
  margin-top: 0.5rem;
}

.all-cont-info {
  margin-top: -5px;
}

.spn-ele {
  font-size: 13px;
  font-weight: 400;
}

.spn-ele a {
  color: var(--cart-description-color);
  text-decoration: none;
}

.spn-ele a:hover {
  color: var(--main-color);
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: none !important;
}

.all-cont-info td span:hover {
  color: var(--main-color);
}

@media only screen and (max-width: 912px) {
  .social-media-icon {
    padding-top: 1rem;
  }

  .txt-para-sec {
    padding: 0px;
  }
}

@media only screen and (max-width: 912px) and (min-width: 768px) {
  .txt-para-sec p {
    padding: 0 1rem 0 0;
  }
}
@media only screen and (max-width: 900px) {
  .footer-email {
    display: none;
  }
}
