

.page_hed {
    background-color: var(--main-color);
    width: 100%;
    height: 30rem;
    padding-top: 2rem; 
    position:absolute;
  }
  
  .page_hed h1 {
    color:var(--white-color);
    text-align: center;
    font-weight: bold;
    font-size: 3.5rem;
    letter-spacing: 1px;
    font-family:var(--primary-font);
  }


  @media only screen and (max-width:915px)and (min-width:600px){
    .page_hed{
      height:24rem;
    }
  }

  @media only screen and (max-width:415px){
    .page_hed h1{
      font-size:34px;
    }
  }


  