/* .third-ban-background {
  background-image: url("../../../Assets/Images/background.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
} */
.third-banner-gradient {
  background: linear-gradient(
    263deg,
    rgb(57, 162, 193, 0.6),
    rgb(57, 162, 193s, 0.6)
  );
}
.third-ban-section {
  background-color: var(--white-color);
  margin-top: 100px;
  padding: 50px 100px 0px 100px;
}
@media only screen and (max-width: 900px) {
  .third-ban-section {
    padding: 50px 20px 20px 20px;
  }
}
.third-ban-section p {
  text-align: justify;
  font-family: var(--primary-font);
  margin-top: 14px;
}

.third-sec-head {
  padding: 15px;
}
.third-ban-img {
  height: 200px ;
  width: 75%;
  overflow: hidden;
  border-radius: 10px 0px 10px 0px;
}
.third-ban-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.about-sec-btn a{
  color: #000 !important;
  text-decoration: none !important;

}
.about-sec-btn a:hover {
color:var(--main-color) !important;
}
.about-sec-btn i {
  display: none;
  font-size: 10px;
}
.about-sec-btn a:hover i {
  display: inline-block;
}

