@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap");
:root {
  /* --main-color: rgb(235, 83, 44); */
  --main-color: #39a2c1;
  --black-color: #000;
  --white-color: #fff;
  --section-margin: 85px 0px 75px 0px;
  --cart-description-color: rgb(123, 123, 123);
  /* --overlay: rgb(183, 147, 48); */
  --overlay: #64579e;
  --read-more: rgb(93, 178, 171);
  --connect-us: rgb(236, 193, 85);
  --footer-icon-color: rgb(238, 134, 116);
  --footer-text-color: rgb(140, 140, 140);
  --facebook-icon-color: rgb(33, 70, 199);
  --instagram-icon-color: rgb(242, 24, 115);
  --twitter-icon-color: rgb(29, 155, 240);
  --primary-font: "Roboto", sans-serif;
}
h1,
h2,
h3,
h4,
h6,
h5,
p,
label {
  font-family: var(--primary-font);
}
a {
  color: var(--white-color);
}
.top-bar-main-section {
  display: flex;
  justify-content: space-around;
  /* margin: 0px 144px; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 25px;
}

@media only screen and (max-width: 1000px) {
  .top-bar-main-section {
    display: none;
  }
}
.main-section {
  margin: var(--section-margin);
}
.section-background {
  background-color: var(--main-color);
  color: white;
}

.section-heading {
  font-weight: 700;
  text-transform: uppercase;
}

/* top navbar */
.top-nav-img {
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.top-nav-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
/* Navbar */
.categories_drop_down_hide {
  /* border-bottom: 1px solid ; */
  padding-bottom: 10px;
  /* display: none; */
}
.dropdown-menu {
  border-radius: 4px !important;

  white-space: nowrap;
  /* max-height: max-content; */
  /* max-width: max-content; */
  width: max-content !important;
}
.navbar-link a {
  /* color: #fff !important; */
  text-decoration: none;
  color: #000 !important;
  margin-left: 20px !important ;
}

.navbar-link a:hover {
  /* color: #fff !important; */
  background-color: none !important;
}
.categories-button {
  border: none !important;
  background-color: var(--main-color) !important;
  color: var(--white-color);
}
.nav-bar-background {
  background-color: var(--main-color) !important;
  color: var(--white-color) !important;
}
.nav-link {
  display: block;
  /* padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x); */
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--white-color) !important;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  margin: 0px 5px;
}
.nav-link:focus,
.nav-link:hover {
  color: white;
}
.lower-border {
  padding-bottom: 13px !important;
}
.lower-border:hover {
  border-bottom: 1px solid;
  display: inline-block;
  padding-bottom: 10px !important;
  width: fit-content;
  transition: 0.3s ease-out;
}

.nav-item {
  padding-bottom: 13px;
}
.nav-item a {
  padding: 0px 0px 10px 0px !important;
  margin: 0px 20px;
}
/* .categories-list {
  margin: 10px 0px !important;
  padding: 2px 0px !important;
}
.categories-list:hover  {
  background-color: var(--main-color) !important;
  color: #fff;
     margin: 10px 16px !important;

}
.categories-list a {
  margin: 0px !important;
} */

.categories-list li a {
  margin: 0px 0px 0px 0px !important ;
  padding: 8px 0px !important;
}
.categories-list li {
  margin: 8px 0px 8px 0px;
  padding: 0px 10px;
}
.categories-list li:hover {
  background-color: var(--main-color);
  /* color: #fff !important; */
  /* margin: 0px 10px !important; */
}
.categories-list li:hover a {
  color: #fff !important;
}

/* button hover effect */
.all-btn-hover-eff button {
  transition: all 0.3s ease-in;
}

.all-btn-hover-eff button:hover {
  transform: scaleX(1.1);
}
.view-btn {
  background-color: var(--main-color);
  color: var(--white-color);
  padding: 6px 16px;
  border: solid 1px var(--white-color);
  border-radius: 10px;
}
.view-btn:hover {
  background-color: var(--black-color);
  border: none;
}
.tabs-main {
  text-align: center !important;
}
.nav-tabs {
  border-bottom: none !important;
  margin-bottom: 26px;
}
.nav-tabs .nav-link {
  color: var(--black-color) !important;
}
.nav-tabs .nav-link.active {
  color: var(--white-color) !important;
}
.btn-tabs {
  padding: 6px 26px;
  background-color: var(--main-color) !important;
  border: none;
}
.tabs-ul {
  padding: 0;
}
.nav {
  display: inline-block;
}
.tabs-li {
  list-style-type: none;
  display: inline-block;
}
.span-icon {
  margin-right: 8px;
}
.gallery-image {
  text-align: center;
}
.footer-ul li {
  list-style-type: none;
}
.footer-ul {
  padding: 0px;
}
.mcq-card {
  background-color: var(--main-color);
  border-radius: 4px;
  /* text-align: center !important; */
  padding: 36px 64px;
}
.mcq-card-qna h3 {
  color: var(--white-color);
  font-weight: bold;
}
.mcq-answer {
}
.check-mcq {
  background-color: var(--white-color);
  /* display: inline-block; */
  padding: 8px 46px;
  border-radius: 12px;
  margin-bottom: 12px;
  margin-left: 46px;
  margin-right: 46px;
  cursor: pointer;
}
.check-mcq-input {
  cursor: pointer;
  background-color: var(--main-color);
}
.mcq-ul {
  padding: 0;
}
.mcq-ul li {
  list-style-type: none;
}
.check-mcq-label {
  margin-left: 16px;
  cursor: pointer;
}
input[type="checkbox"] {
  background-color: var(--main-color) !important;
}
.slick-prev:before {
  display: none !important;
}

.slick-next:before {
  display: none !important;
}
.slider-button {
  text-align: center;
  margin-top: 12px;
}
.slider-button ul {
  padding: 0px;
}
.slider-button ul > li {
  list-style-type: none;
  display: inline-block;
}
.button-slider button {
  border: none;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px 16px;
  background-color: var(--white-color);
  margin-left: 8px;
  letter-spacing: 1px;
  /* font-weight: bold; */
}
.button-slider button:hover {
  background-color: #277086;
  color: var(--white-color) !important;
  /* padding: 4px 16px; */
}
@media only screen and (max-width: 500px) {
  .button-slider button {
    margin-top: 10px;
  }
}
@media only screen and (min-width: 770px) and (max-width: 980px) {
  .button-slider button {
    margin-top: 15px;
  }
}
.qna-input {
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
}
.qna-input:focus-visible {
  outline: none;
}
.qna-ul {
  padding: 0px;
  text-align: center;
}
.qna-li {
  list-style-type: none;
  display: inline-block;
}
.text-start {
  color: var(--black-color) !important;
  font-weight: bold;
}
.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.accordion-button:hover {
  z-index: 2;
}
.accordion-button:not(.collapsed) {
  color: var(--black-color);
  background-color: none !important;
  box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 var(--bs-accordion-border-color);
}
.no-search {
  text-align: center;
}
