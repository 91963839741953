.txt-sec {
  position: relative;
  height: 20rem;
  background: var(--white-color);
  top: -10rem;
}

.txt-hed h2 {
  font-weight: bold;
  font-size: 30px;
  font-family: var(--primary-font);
  text-align:justify;
}

.grp-img {
  height: 500px;
  width: 100%;
  object-fit: cover;
}
.grp-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.grp-img img {
  width: 100%;
  height:100%;
  object-fit:contain;
  margin-top:13rem;
}

.sec-txt-cont {
  font-size: 16px;
  font-weight: bold;
  color: var(--cart-description-color);
  padding: 6rem;
  font-family: var(--primary-font);
  text-align:justify;
  line-height:1.2;
}
.sec-txt-cont p {
  line-height: 30px;
}

.txt-cont {
  position:relative;
  background-color: var(--white-color);
  width: 68%;
  height:auto;
  padding: 6rem;
  top:7rem;
}

.txt-sub-hed {
  padding-top: 1rem;
}

.txt-sub-hed p {
  font-size: 16px;
  font-weight: bold;
  color: var(--cart-description-color);
  font-family: var(--primary-font);
  text-align:justify;
  line-height:1.2;
}

@media only screen and (max-width:912px) {
    .txt-cont{
    padding:2rem;
    }

  .txt-hed h2{
  font-size:24px;
  }

  .txt-sub-hed p{
    font-size:13px;
    margin-top:-1rem;
  } 

.sec-txt-cont {
    font-size: 13px;
    padding: 2rem;
}
}

 @media only screen and (max-width:915px)and (min-width:600px){
.grp-img img{
    margin-top:12rem;
  }
 }




