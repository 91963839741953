/*border radius*/


*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}



.wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 22rem));
    gap: 2rem;
    justify-content: center;
    margin-top:3rem;
}

.infoCard {

    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: background 200ms ease-in;
    padding: 15px ;
    /* box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px; */

}

.infoCard:hover {
    background-color: #fafafa;
}

.infoCard:hover .infoCard__btn {
    background-color: #12728f;
}

.infoCard__image {
    height: 9rem;
    width: 100%;
    object-fit: cover;
    border: 0.125rem solid #838383;
}

.infoCard__name {
    text-align: center;
    padding: 0.7rem 0 0 0;
    margin: 0;
    font-size: 25px;
}

.infoCard__description {
    text-align: center;
    margin: 0;
    padding: 0.7rem 5px 1.5rem 5px;
    text-align: justify;
    height: 110px;
}



.infoCard__btn {
    box-sizing: border-box;
    font-size: 1rem;
    padding:7px 19px;
    border: none;
    color: white !important;
    background-color:var(--main-color);
    transition: background 200ms ease-in, color 200ms ease-in;
    width: 100%;
}
.infoCard__btn a:hover {
    color: var(--white-color) !important;
}



.btn__icon {
    float: right;
}

.btn__text {
    float: left;
}