.ask-your-question button{
    /* border: 1px solid var(--cart-description-color) !important; */
    border: none;
    background-color: var(--read-more);
    overflow: hidden;
}
.ask-to-expert-show  {
    inset: 0px 0px auto 0px !important;
    border-radius: 0px !important;
    overflow: hidden !important;;
}
.ask-question-search{
    border: 1px solid var(--cart-description-color);
    border-radius: 5px;
    padding: 0px 5px;
}
.ask-question-search:hover{
    background-color: var(--main-color);
    color: var(--white-color);
}