.sec-head {
  text-align: center;
}

.para-sec-text h5 {
  text-align: center;
  margin-top: 3.4rem;
  font-weight: 600;
  color: var(--cart-description-color);
  padding: 0 4rem;
  line-height: 35px;
}

.join-us-btn {
  text-align: center;
}

.join-us-btn button {
  padding: 7px 19px;
  margin-top: 3rem;
  background: var(--main-color);
  color: var(--white-color);
  font-size: 15px;
  border-radius: 0 !important;
  border: none !important;
}

.join-us-btn button:hover {
  background-color: var(--overlay);
  color: white !important;
}

.join-us-btn i {
  padding-left: 10rem;
  font-size: 12px;
}




@media only screen and (max-width: 540px) {
  .para-sec-text h5 {
    text-align: justify;
  }
}

@media only screen and (max-width: 912px) {
  .para-sec-text h5 {
    padding: 20px;
  }
}
