.second-banner-image{
    height: 150px ;
    width: auto;
    overflow: hidden;
}

.banner-heading{
    
}
.second-banner-image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.second-ban-des{
    text-align: center;
}
.second-ban-des p
{
color: var(--cart-description-color);
}
.second-ban-des p:hover{
    color: var(--main-color);
}
.second-ban-des a:hover {
text-decoration: none;
}
.second-ban-des i{
    font-size: 10px;
    display: none;
}
.second-ban-des a:hover i{
display: inline-block;
}
.banner-heading h4{
    font-weight: 700;
}
/* .secondbanner-background{
    background-image: url('../../../Assets//Images/background.jpg');
    background-position: center;
    background-size: cover;
    opacity: 0.5;
} */
/* .second-ban-gradient{
    background: linear-gradient(263deg,rgba(235,83,44,0.5), rgba(235,83,44,0.5));
} */
.secondbanner-background{
    position: relative;
}
.water-markimage{
    position: absolute;
    top:-50px;
    right:0;
    opacity: 0.2;
    z-index: -1;
    
}
.water-markimage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.watermark-second{
    position:absolute;
    top: 0px;
    z-index: -1;
    opacity: 0.2;
    
}
@media only screen and (max-width:600px){
    .water-markimage{
        height: 100px;
        width: 100px;
        overflow: hidden;
    } 
    .watermark-second{
        width:100px ;
    height:100px ;
    overflow:hidden ;
    }
}
.watermark-second img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}