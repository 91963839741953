.glossary-word-color h6 {
    color: var(--main-color);
    margin-top: 10px;
}
.glossary-word-color p{
    color: var(--footer-text-color);
    margin-top: 20px;
}
.glossary-word-color h1 {
    font-size: 50px;
}
.glossary-main-card {
    margin: 0px 20px;
}
@media only screen and (min-width:900px){
    .glossary-main-card {
        margin: 0px 120px;
    }
}